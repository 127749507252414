<template>
  <v-form
    ref="form"
    v-model="valid"
    @input="$emit('valid', $event)"
    lazy-validation
  >
    <v-row>
      <v-col
        cols="12"
        sm="2"
      >
        <p class="text-button">Name *</p>
      </v-col>
      <v-col
        cols="12"
        sm="10"
      >
        <v-text-field
          dense
          :disabled="isCreated"
          v-model="endpoint.name"
          required
          :rules="[v => !!v || 'field is required']"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="2"
      >
        <div class="subtitle-1 text--secondary">Protocol *</div>
      </v-col>
      <v-col
        cols="12"
        sm="10"
      >
        <v-select
          class="text-uppercase"
          dense
          v-model="endpoint.protocol"
          :items="endpoint.enum.protocol"
          required
          :rules="[v => !!v || 'field is required']"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-expansion-panels
        focusable
        multiple
        v-model="panel"
      >
        <v-expansion-panel v-if="endpoint.protocol === 'http'">
          <v-expansion-panel-header>Authentication *</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col
                cols="12"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="2"
                  >
                    <div class="subtitle-1 text--secondary">Methods *</div>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="10"
                  >
                    <v-select
                      class="text-capitalize"
                      dense
                      v-model="endpoint.httpOption.auth.method"
                      :items="endpoint.enum.auth.method"
                      required
                      :rules="[v => !!v || 'field is required']"
                    ></v-select>
                  </v-col>
                  <template v-if="endpoint.httpOption.auth.method === 'Basic'">
                    <v-col
                      cols="12"
                      sm="2"
                    >
                      <div class="subtitle-1 text--secondary">Key *</div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="10"
                    >
                      <v-text-field
                        dense
                        v-model="endpoint.httpOption.auth.key"
                        required
                        :rules="[v => !!v || 'field is required']"
                      ></v-text-field>
                    </v-col>
                  </template>
                  <v-col
                    cols="12"
                    sm="2"
                  >
                    <div class="subtitle-1 text--secondary">Secret *</div>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="10"
                  >
                    <v-text-field
                      dense
                      required
                      v-model="endpoint.httpOption.auth.secret"
                      :rules="[v => !!v || 'field is required']"
                      :append-icon="isSecret ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="isSecret ? 'text' : 'password'"
                      @click:append="isSecret = !isSecret"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                  ></v-col>
                  <v-col
                    cols="12"
                    sm="10"
                  ></v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="endpoint.protocol === 'mqtt'">
          <v-expansion-panel-header>MQTT Configuration *</v-expansion-panel-header>
          <v-expansion-panel-content>
              <v-row>
                <v-col
                  cols="12"
                  sm="2"
                >
                  <div class="subtitle-1 text--secondary">Connections *</div>
                </v-col>
                <v-col
                  v-if="!isNewConnections"
                  cols="12"
                  sm="9"
                >
                  <v-select
                    class="text-capitalize"
                    dense
                    v-model="endpoint.mqttOption.connection"
                    :items="connectionsList"
                    item-text="_source.name"
                    item-value="_id"
                    required
                    :rules="[v => !!v || 'field is required']"
                  ></v-select>
                </v-col>
                <v-col
                  v-else
                  cols="12"
                  sm="9"
                >
                  <mqtt-connections-form ref="connectionsFn" v-model="mqttTemplate.connections"
                    :connectionsList="connectionsList"
                    :ready="connectionsReady = true" @valid="connectionsFormValid = $event, $emit('valid', $event)" />
                  <!-- <v-btn
                    class="mt-n6"
                    small
                    rounded
                    color="success"
                    :disabled="!connectionsFormValid"
                    @click="createConnections()"
                  >
                    Create
                  </v-btn> -->
                </v-col>
                <v-col
                  cols="12"
                  sm="1"
                >
                  <v-tooltip
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :class="{'success': !isNewConnections, 'warning': isNewConnections, 'ml-4': true}"
                        v-bind="attrs"
                        v-on="on"
                        fab
                        dark
                        x-small
                        @click="handleToggleNewConnection"
                      >
                        <v-icon dark v-if="!isNewConnections">
                          mdi-plus
                        </v-icon>
                        <v-icon dark v-else>
                          mdi-minus
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Add New Connection</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="2"
                >
                  <div class="subtitle-1 text--secondary">Subscriptions *</div>
                </v-col>
                <v-col
                  v-if="!isNewSubscriptions"
                  cols="12"
                  sm="9"
                >
                  <v-select
                    class="text-capitalize"
                    dense
                    v-model="endpoint.mqttOption.subscription"
                    :items="filteredSubscriptionsList"
                    item-text="_source.name"
                    item-value="_id"
                    required
                    :rules="[v => !!v || 'field is required']"
                  ></v-select>
                </v-col>
                <v-col
                  v-else
                  cols="12"
                  sm="9"
                >
                  <v-expand-transition>
                    <mqtt-subscriptions-form ref="subscriptionsFn" :connections="connectionsListFilter" v-model="mqttTemplate.subscriptions"
                      :ready="subscriptionsReady = true" @valid="subscriptionsFormValid = $event, $emit('valid', $event)" />
                  </v-expand-transition>
                  <!-- <v-btn
                    class="mt-n6"
                    small
                    rounded
                    color="success"
                    :disabled="!subscriptionsFormValid"
                    @click="createSubscriptions()"
                  >
                    Create
                  </v-btn> -->
                </v-col>
                <v-col
                  cols="12"
                  sm="1"
                >
                  <v-tooltip
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :class="{'success': !isNewSubscriptions, 'warning': isNewSubscriptions, 'ml-4': true}"
                        v-bind="attrs"
                        v-on="on"
                        fab
                        dark
                        x-small
                        @click="isNewSubscriptions = !isNewSubscriptions"
                      >
                        <v-icon v-if="!isNewSubscriptions">
                          mdi-plus
                        </v-icon>
                        <v-icon v-else>
                          mdi-minus
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Add New Subscriptions</span>
                  </v-tooltip>
                </v-col>
              </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>Parse Request</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col
                cols="12"
                sm="2"
              >
                <div class="subtitle-1 text--secondary">Error Handle</div>
              </v-col>
              <v-col
                cols="12"
                sm="10"
              >
                <v-select
                  class="text-capitalize"
                  dense
                  v-model="endpoint.parseRequest.errorHandler"
                  :items="endpoint.enum.parseRequest.errorHandler"
                ></v-select>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-row>
      <v-col
        class="mt-4"
        cols="12"
        sm="11"
      >
        <p class="text-button">Processors (Tasks) *</p>
      </v-col>
      <v-col
        cols="12"
        sm="1"
      >
        <v-tooltip
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="success mt-5"
              v-bind="attrs"
              v-on="on"
              fab
              dark
              x-small
              @click="addProcessorGroup()"
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
          <span>Add New Group</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <!-- {{ $vuetify.breakpoint.name }} -->
      <v-col
        class="mt-n6"
        cols="12"
        :sm="3"
      >
        <v-list
          expand
          dense
          rounded
        >
          <processor-list-menu
            v-model="endpoint.processors"
            :taskSelectIndexObj="taskSelectIndexObj"
            @updateTaskSelect="taskSelectIndexObj = $event"
            @taskSelect="navigationSelect = $event"
          />
        </v-list>
      </v-col>
      <v-col>
        <end-point-processor-form
          v-if="navigationSelect"
          v-model="navigationSelect"
        />
      </v-col>
    </v-row>
    <!-- <small>*indicates required field</small> -->
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/services/api'
import nestapi from '@/services/nest'
// import nestApi from '@/services/nest'
import cleanDeep from 'clean-deep'
import mqttTemplate from '@/template/mqttTemplate.json'

export default {
  name: 'EndPointForm',
  model: {
    prop: 'endpoint',
    event: 'input'
  },
  components: {
    EndPointProcessorForm: () => import('@/components/end-point/EndPointProcessorForm'),
    ProcessorListMenu: () => import('@/components/ProcessorListMenu.vue'),
    MqttConnectionsForm: () => import('@/components/mqtt/MqttConnectionsForm'),
    MqttSubscriptionsForm: () => import('@/components/mqtt/MqttSubscriptionsForm')
  },
  props: {
    endpoint: {
      type: Object,
      default () {
        return {}
      },
      required: true
    },
    connectionsList: {
      type: Array,
      default: () => [],
      required: false
    },
    subscriptionsList: {
      type: Array,
      default: () => [],
      required: false
    },
    isCreated: {
      type: Boolean,
      required: false,
      default: false
    },
    isFullscreen: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      rre: null,
      valid: true,
      checkValid: false,
      connectionsFormValid: true,
      subscriptionsFormValid: true,
      panel: [0, 1, 2],
      isSecret: false,
      navigationSelect: null,
      taskSelectIndexObj: {
        groupLevel: 0,
        groupIndex: 0,
        taskIndex: null
      },
      isNewConnections: false,
      isNewSubscriptions: false,
      newConnectionForm: {},
      newSubscriptionForm: {},
      mqttTemplate: JSON.parse(JSON.stringify(mqttTemplate)),
      newConnectionsResp: {},
      newSubscriptionsResp: {},
      connectionsReady: false
    }
  },
  methods: {
    addProcessorGroup: function () {
      this.endpoint.processors.L.push({ L: [] })
    },
    addProcessorTask: function (task, groupIndex, taskIndex) {
      console.log(task, groupIndex, taskIndex)
      let optionTemplate = {}
      if (task === 'tasks.s3Keeper') {
        optionTemplate = this.endpoint.taskList.s3Keeper
      } else if (task === 'tasks.logger') {
        optionTemplate = this.endpoint.taskList.logger
      } else if (task === 'tasks.mustache') {
        optionTemplate = this.endpoint.taskList.mustache
      } else if (task === 'tasks.httpRequest') {
        optionTemplate = this.endpoint.taskList.httpRequest
      } else if (task === 'tasks.windowAggregator') {
        optionTemplate = this.endpoint.taskList.windowAggregator
      } else if (task === 'tasks.iterator') {
        optionTemplate = this.endpoint.taskList.iterator
      }
      this.endpoint.processors[groupIndex][taskIndex].optionTemplate = optionTemplate
    },
    addNewTask: function (processorLIndex, itemName) {
      const task = this.endpoint.taskList[itemName.split(/\./g).pop()]
      let processor = JSON.parse(JSON.stringify(this.endpoint.processorTemplate))
      processor.name.S = itemName
      processor.optionTemplate = task
      this.endpoint.processors.L[processorLIndex].L.push({ M: processor })
    },
    updateEndPoint: function () {
      // Object.assign({}, this.filterEndpoint, { processors: JSON.stringify(this.filterEndpoint.processors) }),
      api.put(`/api/services/${this.fiwareService}/endpoints/${this.endpoint.name}`,
        this.filterEndpoint, null, null, this.$store.state.tokenRPT).then((response) => {
        // console.log(response.status)
        const msg = this.globalToastFn(`Update: ${this.endpoint.name}`, response.statusText)
        if (response.status === 200) {
          this.$toast.success(msg)
          this.$emit('updated')
        } else {
          this.$toast.error(msg)
        }
        this.$router.push({ name: 'End-Point' })
      })
    },
    createConnections: function () {
      this.connectionsFormValid = this.$refs.connectionsFn.$refs.form.validate()
      if (this.connectionsFormValid) {
        Promise.resolve(this.$refs.connectionsFn.createConnection())
          .then((ret) => {
            if (ret) {
              this.$emit('update:connectionsList', [...this.connectionsList, { '_id': ret['_id'], '_source': this.mqttTemplate.connections }])
              this.endpoint.mqttOption.connection = ret['_id']
              console.log(this.connectionsList.length)
              if (this.connectionsList.length === 1) {
                this.mqttTemplate.subscriptions.connection = ret['_id']
              }
              this.isNewConnections = false
              this.$toast.success(this.globalToastFn('Create: Connections', 'success'))
            } else {
              this.$toast.warning(this.globalToastFn('Create: Connections', 'something went wrong!'))
            }
          })
      }
    },
    createSubscriptions: function () {
      this.subscriptionsFormValid = this.$refs.subscriptionsFn.$refs.form.validate()
      if (this.subscriptionsFormValid) {
        Promise.resolve(this.$refs.subscriptionsFn.createSubscription())
          .then((ret) => {
            if (ret) {
              this.$emit('update:subscriptionsList', [...this.subscriptionsList, { '_id': ret['_id'], '_source': this.mqttTemplate.subscriptions }])
              this.endpoint.mqttOption.subscription = ret['_id']
              this.isNewSubscriptions = false
              this.$toast.success(this.globalToastFn('Create: Subscriptions', 'success'))
            } else {
              this.$toast.warning(this.globalToastFn('Create: Subscriptions', 'something went wrong!'))
            }
          })
      }
    },
    createEndpoint: function () {
      nestapi.put(`/api/services/${this.fiwareService}/endpoints/${this.endpoint.name}`, this.filterEndpoint, null, null, this.$store.state.tokenRPT).then((response) => {
      // nestapi.post(`/api/services/${this.fiwareService}/endpoints/`, { protocol: 'http', processors: 'hello' }, null, null, this.$store.state.tokenRPT).then((response) => {
        const msg = this.globalToastFn(`Create: ${this.endpoint.name}`, response.statusText)
        // console.log(response.status)
        if (response.status === 200) {
          this.$toast.success(msg)
        } else {
          this.$toast.error(msg)
        }
        // this.$emit('create')
      })
    },
    createEndpoint__: function () {
      Promise.resolve()
        .then(() => {
          if (this.isNewConnections) {
            console.log(1)
            return this.$refs.connectionsFn.createConnection()
          }
        })
        .then((ret) => {
          if (this.isNewConnections) {
            if (ret) {
              this.$emit('update:connectionsList', [...this.connectionsList, { '_id': ret['_id'], '_source': this.mqttTemplate.connections }])
              this.endpoint.mqttOption.connection = ret['_id']
              this.isNewConnections = false
              this.$toast.success(this.globalToastFn('Create: Connections', 'success'))
              return ret
            } else {
              this.$toast.warning(this.globalToastFn('Create: Connections', 'something went wrong!'))
            }
          }
        })
        .then((ret) => {
          if (this.isNewSubscriptions) {
            if (ret) {
              if (this.connectionsList.length === 1) {
                this.mqttTemplate.subscriptions.connection = ret['_id']
              }
              return this.$refs.subscriptionsFn.createSubscription()
            }
          }
        })
        .then((ret) => {
          if (this.isNewSubscriptions) {
            if (ret) {
              this.$emit('update:subscriptionsList', [...this.subscriptionsList, { '_id': ret['_id'], '_source': this.mqttTemplate.subscriptions }])
              this.endpoint.mqttOption.subscription = ret['_id']
              this.isNewSubscriptions = false
              this.$toast.success(this.globalToastFn('Create: Subscriptions', 'success'))
            } else {
              this.$toast.warning(this.globalToastFn('Create: Subscriptions', 'something went wrong!'))
            }
          }
        })
        .then(() => {
          nestapi.put(`/api/services/${this.fiwareService}/endpoints/${this.endpoint.name}`, { protocol: 'http', processors: 'hello' }, null, null, this.$store.state.tokenRPT).then((response) => {
            const msg = this.globalToastFn(`Create: ${this.endpoint.name}`, response.statusText)
            // console.log(response.status)
            if (response.status === 200) {
              this.$toast.success(msg)
            } else {
              this.$toast.error(msg)
            }
            // this.$emit('create')
          })
        })
    },
    deleteEndPoint: function () {
      api.delete(`/api/services/${this.fiwareService}/endpoints/${this.endpoint.name}`, null, null, this.$store.state.tokenRPT).then((response) => {
        const msg = this.globalToastFn(`Delete: ${this.endpoint.name}`, response.statusText)
        if (response.status === 200) {
          this.$toast.success(msg)
        } else {
          this.$toast.error(msg)
        }
        // console.log(this.$route.path)
        this.$router.push({ name: 'End-Point', path: '/end-point' })
      })
    },
    validateEndpointForm () {
      let connValid = true
      let subValid = true
      if (this.$refs.connectionsFn) {
        connValid = this.$refs.connectionsFn.$refs.form.validate()
        this.connectionsFormValid = connValid
      }
      if (this.$refs.subscriptionsFn) {
        subValid = this.$refs.subscriptionsFn.$refs.form.validate()
        this.subscriptionsFormValid = subValid
      }
      // if (this.connectionsReady) {
      // }
      // if (this.subscriptionsReady) {
      // }
      const isValid = this.$refs.form.validate() && connValid && subValid
      // this.$emit('valid', isValid)
      this.$emit('checkValid', isValid)
    },
    resetValidateForm: function () {
      this.$refs.form.resetValidation()
      if (this.connectionsReady) this.$refs.connectionsFn.$refs.form.resetValidation()
      if (this.subscriptionsReady) this.$refs.subscriptionsFn.$refs.form.resetValidation()
      this.navigationSelect = null
    },
    handleToggleNewConnection: function () {
      if (this.isNewConnections) {
        this.isNewConnections = false
        this.isNewSubscriptions = false
      } else {
        this.isNewConnections = true
        this.isNewSubscriptions = true
      }
    },
    resetForm: function () {
      this.mqttTemplate = JSON.parse(JSON.stringify(mqttTemplate))
    }
  },
  computed: {
    ...mapGetters(['fiwareService']),
    filterEndpoint: function () {
      let endpoint = JSON.parse(JSON.stringify(this.endpoint))
      let cleanEndpoint = {
        protocol: endpoint.protocol,
        processors: Object.keys(cleanDeep(endpoint.processors)).length === 0 ? '[]' : JSON.stringify(cleanDeep(endpoint.processors))
      }
      if (endpoint.protocol === 'http') {
        cleanEndpoint.httpOption = cleanDeep(endpoint.httpOption)
      } else if (endpoint.protocol === 'mqtt') {
        let connectionIdx = this.connectionsList.findIndex((c) => c._id === endpoint.mqttOption.connection)
        let subscriptionIdx = this.subscriptionsList.findIndex((s) => s._id === endpoint.mqttOption.subscription)
        // console.log(connectionIdx, subscriptionIdx)
        cleanEndpoint.mqttOption = cleanDeep(Object.assign({}, endpoint.mqttOption,
          {
            connection: {
              '_id': this.connectionsList[connectionIdx]['_id'],
              '_source': this.connectionsList[connectionIdx]['_source']
            },
            subscription: {
              '_id': this.subscriptionsList[subscriptionIdx]['_id'],
              '_source': this.subscriptionsList[subscriptionIdx]['_source']
            }
          }
        ))
      }
      if (endpoint.parseRequest && endpoint.parseRequest.errorHandler) cleanEndpoint.parseRequest = endpoint.parseRequest
      return cleanEndpoint
    },
    vv: function () {
      return this.valid
    },
    filteredSubscriptionsList: function () {
      let vm = this
      if (vm.endpoint.mqttOption.connection) {
        return this.subscriptionsList.filter((s) => s._source.connection === vm.endpoint.mqttOption.connection)
      } else {
        return []
      }
    },
    connectionsListFilter: function () {
      let connList = this.connectionsList
      if (this.isNewConnections && this.endpoint.mqttOption.connection) {
        connList = [...this.connectionsList, { '_id': this.endpoint.mqttOption.connection, '_source': this.mqttTemplate.connections }]
      }
      return connList
    }
    // filterConnectionsList: function () {
    //   // return this.connectionsList
    //   if (this.newConnectionForm) {
    //     const array = [...this.connectionsList, { '_id': `urn:mep-backend:resources:services:${this.fiwareService}:connections:${this.mqttTemplate.connections.name}`, '_source': this.mqttTemplate.connections }]
    //     const uniqueArray = array.filter((item, pos) => {
    //       return array.indexOf(item) === pos
    //     })
    //     return uniqueArray
    //   } else {
    //     return this.connectionsList
    //   }
    // }
  },
  watch: {
    'mqttTemplate.connections.name': function (val) {
      this.endpoint.mqttOption.connection = `urn:mep-backend:resources:services:${this.fiwareService}:connections:${val}`
    }
  },
  created () {
  }
}
</script>
